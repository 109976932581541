<template>
  <div class="entry-card-content">
    <div class="mgb10"><el-button type="primary" size="medium" @click="addEntryCard">添加</el-button></div>
    <div v-for="(itr,index) in entryCardList" class="list-group">
     <el-form :model="itr" size="medium" :ref="'entrCardForm_'+index">
       <el-form-item label="入职主体" label-width="90px" prop="subjectId" :rules="[
           {required:true,message:'请选择入职主体',trigger:'change'}
       ]">
         <el-select v-model="itr.subjectId" :disabled="!itr.canEdit">
           <el-option v-for="option in Contracts" :key="option.value" :value="option.value" :label="option.text"> </el-option>
         </el-select>
       </el-form-item>
       <el-form-item class="mgb0 entry-card-img required" prop="imageUrl" :rules="[
          { required:true,message:'请选择图片',trigger:['change']}
       ]">
          <label class="form-label">
            文件
          </label>
         <span class="label-tips">图片建议：尺寸900*383，支持jpg,png,jpeg格式图片，大小不能超过5M</span>
           <div>
             <el-upload action="/business/upload" list-type="picture-card"
                        :before-upload="(file)=>beforeUpload(file,itr)"
                        :file-list="itr.imageUrl?[itr]:[]"
                        accept=".jpg,.png,.jpeg"
                        :before-remove="(file)=>beforeRemove(file,itr)"
                        :http-request="(file)=>uploadFile(file,index)"
                        :on-change="changeFile"
                        :disabled="!itr.canEdit">
               <i slot="default"  class="el-icon-plus"></i>
               <div slot="file" slot-scope="{file}">
                 <img class="el-upload-list__item-thumbnail"  :src="file.imageUrl" alt="">
                 <span class="el-upload-list__item-actions">
                   <span class="el-upload-list__item-preview" @click="handlePreview(itr)"><i class="el-icon-zoom-in"></i></span>
                   <span class="el-upload-list__item-delete"  @click="handleDelete(file,index)" v-if="itr.canEdit"><i class="el-icon-delete"></i></span>
                </span>
               </div>
             </el-upload>
             <el-dialog :visible.sync="showPreview" width="600px">
               <img width="100%" :src="dialogImageUrl" alt="">
             </el-dialog>
           </div>
       </el-form-item>
     </el-form>
      <div class="bottom-btn textRight">
        <el-button type="primary" size="medium" @click="editEntryCard(index)" v-if="!itr.canEdit">编辑</el-button>
        <el-button type="primary" size="medium" @click="saveEntryCard(itr,index)" v-if="itr.canEdit" :loading="itr.isSubmiting">保存</el-button>
        <el-button type="danger" size="medium" @click="deleteEntryCard(itr,index)">删除</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import uploadCommon from "../../../../../utils/upload";
import {deleteFIle} from "../../../../../services/check";

export default {
  name: "entryCard",
  data(){
    return {
      Contracts:[],
      entryCardList:[],
      showPreview:false,
      dialogImageUrl:''
    }
  },
  created() {
    this.getContracts();
    this.getList();
  },
  methods:{
    getContracts(){
      this.$api.personnel.getContractsList({pageNum:1,pageSize:10000}).then(response=>{
        this.Contracts = response.data;
      })
    },

    getList(){
      this.$api.personnel.getEntryCardList({}).then(response=>{
        this.entryCardList = response.data
      })
    },

    handlePreview(data){
     this.showPreview = true;
     this.dialogImageUrl = data.imageUrl
    },

    handleDelete(file,index){
      return new Promise((resolve,reject)=>{
        let fileUrl = file.imageUrl
        this.entryCardList[index].imageUrl='';
        deleteFIle({
          fileUrl:fileUrl,
        }).then(res=>{
          resolve()
        })
      })
    },

    beforeUpload(file,data){
      //验证文件格式和大小最大100M
      return uploadCommon.checkFileAndSize(file,5,['jpg','png','jpeg']);
    },

    changeFile(file,fileList){
      const result = uploadCommon.checkFileAndSize(file,5,['jpg','png','jpeg']);
      if(result){
        if(fileList.length>1){
          fileList.shift()
        }
      }else{
        if(fileList.length>1){
          fileList.pop()
        }
      }
    },

    uploadFile(fileData,index){
      const file = fileData.file;
      //上传给后端，拿到url
      let form = new FormData;
      form.append("file",file);
      this.$api.sysapi.uploadFile(form,'entryCard')
          .then(({code, data, msg})=>{
            if(code === 200){
              this.entryCardList[index].imageUrl = data;
              const formId = `entrCardForm_${index}`;
              this.$refs[formId][0].validateField('imageUrl')
            }
          })
    },

    saveEntryCard(data,index){
      const fromId = `entrCardForm_${index}`;
      this.$refs[fromId][0].validate((valid)=>{
        if(valid){
          this.$set( data,'isSubmiting',true)
          let param = {
            id:data.id||'',
            imageUrl:data.imageUrl||'',
            subjectId:data.subjectId
          }
          this.$api.personnel.saveEntryCard(param).then(response=>{
            this.$message.success('保存成功');
            this.$set( data,'id',response.data.id);
            this.$set( data,'isSubmiting',false)
            this.$set( data,'canEdit',false)
          }).catch(err=>{
            this.$set( data,'isSubmiting',false)
          })
        }
      })
    },

    deleteEntryCard(itr,index){
      this.$confirm('是否删除当前数据？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        if(itr.id){
          this.$api.personnel.delEntryCard(itr.id).then(response=>{
            this.$message.success('删除成功');
            this.entryCardList.splice(index,1);
          })
        }else{
          if(itr.imageUrl){
            this.handleDelete(itr,index).then(res=>{
              this.entryCardList.splice(index,1)
              this.$message.success('删除成功');
            })
          }else{
            this.entryCardList.splice(index,1)
            this.$message.success('删除成功');
          }
        }
      })
    },

    addEntryCard(){
      this.$set(this.entryCardList,this.entryCardList.length,{
        id:'',
        imageUrl:'',
        subjectId:this.Contracts[0].value,
        canEdit:true,
        isSubmiting:false,
      })
    },

    editEntryCard(index){
      this.$set( this.entryCardList[index],'canEdit',true)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload--picture-card,
::v-deep .el-upload-list__item{
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-left: 15px;
}
::v-deep   .entry-card-img .el-form-item__error{
  margin-left: 20px;
}
  .entry-card-content{
    width: 80%;
    margin: 0 auto;
    .list-group{
      border: 1px solid #d2d2d2;
      padding: 20px;
      border-radius: 8px;
      & +.list-group{
        margin-top: 10px;
      }
      .form-label{
        text-align: right;
        padding: 0 12px 0 0;
        width: 60px;
      }
      .label-tips{
        font-size: 13px;
        color: #adabab;
        line-height: 40px;
        display: inline-block;
      }
    }
  }

</style>