<template>
  <div class="remind-setting-wrapper">
    <div class="main-content">
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="入职卡片" name="entryCard">
          <entry-card v-if="activeName==='entryCard'"></entry-card>
        </el-tab-pane>
        <el-tab-pane label="转正提醒" name="positiveRemind">
          <date-remind :type="1" v-if="activeName==='positiveRemind'"></date-remind>
        </el-tab-pane>
        <el-tab-pane label="合同到期提醒" name="contractRemind">
          <date-remind  :type="2" v-if="activeName==='contractRemind'"></date-remind>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import EntryCard from "./components/entry-card";
import DateRemind from "./components/date-remind";
export default {
  name: "remind-setting",
  components: {DateRemind, EntryCard},
  data(){
    return {
      activeName:'entryCard'
    }
  }
}
</script>

<style scoped lang="scss">
  .main-content{
    flex: 1;
    display: flex;
    .el-tabs{
      flex: 1;
    }
  }

</style>